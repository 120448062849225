import React, { useEffect, useState } from "react";
import { Renderer } from "@bishad/test-scope.ui.renderer";
const BASE_URL = "https://bitconfig.uzeli.com/"
const RenderApp = ({ hash }) => {
  const [config, setConfig] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(BASE_URL+hash+'.json').then((res)=>res.json())
        console.log(response)
        setConfig(response)
      } catch (e) {
        console.log(e);
        setError(" !!!!! ERROR :  INVALID HASH !!!!!");
      }
    })();
    console.log("test")
  }, [hash]);

  if (!!error) {
    return <div style={{ backgroundColor: "red", color: "white", height: "30px" }}>{error}</div>;
  }
  return <Renderer config={config} />;
};

export default RenderApp;
